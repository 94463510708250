<template>
    <section class="homepage">
        <NavBar />
        <div class="homepage__content">
            <HeaderTop />
            <HeaderBottom />
        </div>
    </section>
</template>

<script>
import HeaderBottom from '@/components/HeaderBottom.vue';
import HeaderTop from '@/components/HeaderTop.vue';
import NavBar from '@/components/NavBar.vue';

export default {
    components: {
        NavBar, HeaderTop, HeaderBottom
    }
}

</script>

<style scoped>
.homepage {
    display: flex;
    gap: 5vw;
}

.homepage__content {
    width: 100%;
}
</style>