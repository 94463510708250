<template>
    <div class="navbar">
        <div class="navbar__item">

        </div>
        <ul class="navbar__list">
            <li class="navbar__item">

            </li>
            <li class="navbar__item">
                
            </li>
            <li class="navbar__item">
                
            </li>
            <li class="navbar__item">
                
            </li>
            <li class="navbar__item">
                
            </li>
            <li class="navbar__item">
                
            </li>
        </ul>
    </div>
</template>

<style scoped>

.navbar {
    width: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.navbar__list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    list-style: none;
}

.navbar__item {
    width: 3vw;
    height: 3vw;
    background-color: #252A2F;
    border-radius: 50px;
    cursor: pointer;
}

</style>