<template>
  <HomePage />
</template>

<script>

import HomePage from './pages/HomePage.vue';

export default {
  name: 'App',
  components: { HomePage },
};
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

#app {
  background: #191D20;
  width: 100%;
  height: 100vh;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


.icon {
    width: 1.5vw;
    height: 1.5vw;
}

</style>
