<template>
    <header class="header">
        <div class="header__search">
            <img src="@/assets/search.png" class="icon" alt="Поиск" />
            <input type="text" class="search__input" placeholder="Поиск" />
        </div>
        <div class="header__nav">
            <img src="@/assets/calendar.png" class="icon calendar" alt="Календарь" />
            <div class="header__user">
                <img src="@/assets/user.png" class="header__photo" />
            </div>
        </div> 
    </header>
</template>

<style>

.header {
    width: 100%;
    height: 40px;
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
    margin-bottom: 50px;
}

.header__nav {
    display: flex;
    align-items: center;
    gap: 20px;
}

.header__user {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.header__photo {
    width: 3vw;
    height: 3vw;
    border-radius: 100%;
    object-fit: cover;
}

.header__user:active .arrow {
    transform: rotate(180deg);
}



.header__search {
    width: 35vw;
    height: 3vw;
    background-color: #252A2F;
    border-radius: 30px;
    display: flex;
    gap: 1vw;
    padding: 10px;
    align-items: center;

}

.arrow {
    width: 10px;
    height: 10px;
    transition: .2s;
}

.search__input {
    background: transparent;
    font-size: 1.1vw;
    font-weight: 500;
    border: none;
    outline: none;
    color: #a2a2a2;
    width: 300px;
}

.calendar {
    cursor: pointer;
}

</style>