<template>
    <div class="header__bottom">
        <h1>{{ message }}, {{ name }}!</h1>
        <div class="header__chtoeto"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            message: "",
            name: "Сергей",
        };
    },
    mounted() {
        this.setGreetingMessage();
    },
    methods: {
        setGreetingMessage() {
            const hours = new Date().getHours();
            if (hours >= 5 && hours < 12) {
                this.message = "Доброе утро";
            } else if (hours >= 12 && hours < 18) {
                this.message = "Добрый день";
            } else if (hours >= 18 && hours < 22) {
                this.message = "Добрый вечер";
            } else {
                this.message = "Доброй ночи";
            }
        }
    }
};
</script>

<style scoped>

.header__bottom {
    display: flex;
    gap: 30px;
    align-items: center;
}

.header__chtoeto {
    width: 20vw;
    height: 1.5vw;
    background-color: #6C7883;
    opacity: 0.5;
    border-radius: 30px;
}

h1 {
    color: #FFF;
    font-size: 2.5vw;
}
</style>
